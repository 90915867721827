const modules = {
  '[data-component="carousel"]': () => import('./modules/swiper'),
  '[data-component="stats"]': () => import('./modules/stats'),
};

// export default scope => {
//   Object.keys(modules).forEach(selector => {
//     const request = modules[selector];
//
//     (els => {
//       if (els && els.length) {
//         request().then(({ default: module }) => module(els));
//       }
//     })(scope.querySelectorAll(selector));
//   });
// };

export default scope => {
  Object.keys(modules).forEach(selector => {
    const request = modules[selector];
    
    (els => {
      if (els && els.length) {
        request().then(({ default: module }) => {
          // Debugging: Log the module to see what is being imported
          console.log(`Module for selector "${ selector }":`, module);
          
          // Check if the module is a function before calling it
          if (typeof module === 'function') {
            module(els);
          } else {
            console.error(`The module for selector "${ selector }" is not a function.`);
          }
        }).catch(error => {
          console.error(`Failed to load module for selector "${ selector }":`, error);
        });
      }
    })(scope.querySelectorAll(selector));
  });
};
